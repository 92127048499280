<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="700px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Edit profile
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text style="font-family:'Poppins-Regular';">
        Edit profile information.
      </v-card-text>
      <v-card-text class="justify-center d-flex flex-column">
        <v-row>
          <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6" class="py-0">
            <v-text-field
              v-model="profile.email"
              label="Email*"
              dense
              filled
              background-color="white"
              @input="onChange"
              outlined
              :rules="[rules.required, rules.email]"
            />
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.xs ? 12 : 6"
            class="py-0"
            :class="$vuetify.breakpoint.xs ? 'mb-7' : ''"
          >
            <AppPhoneNumberInput
              v-model="profile.phone"
              @input="onChange"
              @update="onUpdate"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <v-select
              :items="ageOptions"
              v-model="profile.age_group"
              label="Age*"
              flat
              outlined
              dense
              @input="onChange"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn
          color="#b5e539"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          :disabled="this.phonePayload.isValid === false"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AppPhoneNumberInput from "../../../../components/common/AppPhoneNumberInput.vue";
export default {
  components: { AppPhoneNumberInput },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function
    }
  },
  data() {
    return {
      message: null,
      loading: false,
      rules: {
        required: value => !!value || "Required.",
        email: value => {
          const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;
          const invalidPatterns = [
            /@.*\.\./, // Double dots
            /@[^a-zA-Z0-9-]/, // Invalid characters in domain
            /@-/, // Domain starts with a hyphen
            /@\./, // Domain starts with a dot
            /\s/, // Spaces in email
            /@.*_/, // Underscore in domain
            /@.*,$/, // Comma instead of dot
            /@.*\.$/, // Extra dot at the end
            /^[^@]+@$/, // Missing domain after @
            /^@/, // Missing local part before @
          ];
          if (!pattern.test(value)) return "Invalid email address.";
          if (invalidPatterns.some(regex => regex.test(value))) return "Invalid email address.";
          return true;
        },
      },
      profile: {},
      phonePayload: {},
      ageOptions: [
        "13-18",
        "19-24",
        "25-34",
        "35-44",
        "45-54",
        "55-64",
        "65 and older"
      ]
    };
  },
  methods: {
    ...mapActions("auth", {
      updateProfile: "updateProfile"
    }),
    onChange() {
      this.message = null;
    },
    onUpdate(payload) {
      this.phonePayload = payload;
    },
    onSave() {
      if (!this.profile.email) {
        this.message = "Please add your email.";
        return;
      }
      if (!this.profile.phone) {
        this.message = "Please add your phone.";
        return;
      }
      if (!this.profile.age_group) {
        this.message = "Please select your age group.";
        return;
      }
      this.message = null;
      this.loading = true;
      let phone = this.profile.phone;
      if (
        this.phonePayload.isValid &&
        this.phonePayload.countryCallingCode != "1"
      ) {
        phone = `+${this.phonePayload.countryCallingCode} ${phone}`;
      }
      this.updateProfile({
        email: this.profile.email,
        phone,
        age_group: this.profile.age_group
      })
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch(error => {
          console.log(error,'error');
          
          this.loading = false;
          if (error.response) {
            this.message = error.response.data.message;
          } else {
            this.message = error;
          }
        });
    }
  },
  computed: {
    ...mapState("auth", {
      myProfile: "profile"
    })
  },
  watch: {
    myProfile(newValue) {
      this.profile = { ...newValue };
    }
  },
  mounted() {
    this.profile = { ...this.myProfile };
  }
};
</script>
<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
}
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
